import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    category: '',
    user: null,
    firestoreDB: null,
    firestoreAuth: null,
    focus: 'running',
    authenticated: false,
    running_demos: [],
    planned_demos: [],
    scenarios: [],
    scenariosLab: [],
    instance: null,
    terminateInstance: false,
    snapshotId: null,
    snapshotName: '',
    snapshotCategory: '',
    snapshotNbr: 0,
    createSnapshot: false,
    deleteSnapshot: false,
    startSnapshot: false,
    ipAddress: '',
    otherMail: null,
    mailUserName: null,
    lastLogin: null,
    steeldemoUpdates: false,
    demoPurposeRecord: {},
    reportees: [],
    signature: [],
    videoLogs: [],
    managerList: {},
    memberOfDashboardViewers: false
  },
  getters: {
    GET_USER: state => state.user,
    category: state => state.category,
    focus: state => state.focus,
    firestoreDB: state => state.firestoreDB,
    authenticated: state => state.authenticated,
    running_demos: state => state.running_demos,
    planned_demos: state => state.planned_demos,
    scenarios: state => state.scenarios,
    scenariosLab: state => state.scenariosLab,
    instance: state => state.instance,
    terminateInstance: state => state.terminateInstance,
    snapshotId: state => state.snapshotId,
    snapshotName: state => state.snapshotName,
    snapshotCategory: state => state.snapshotCategory,
    snapshotNbr: state => state.snapshotNbr,
    createSnapshot: state => state.createSnapshot,
    deleteSnapshot: state => state.deleteSnapshot,
    startSnapshot: state => state.startSnapshot,
    ipAddress: state => state.ipAddress,
    otherMail: state => state.otherMail,
    mailUserName: state => state.mailUserName,
    lastLogin: state => state.lastLogin,
    steeldemoUpdates: state => state.steeldemoUpdates,
    demoPurposeRecord: state => state.demoPurposeRecord,
    reportees: state => state.reportees,
    managerList: state => state.managerList,
    memberOfDashboardViewers: state => state.memberOfDashboardViewers,
    videoLogs: state => state.videoLogs,
    signature: state => state.signature
  },
  mutations: {
    SELECT_CATEGORY: (state, category) => {
      state.category = category
    },
    SELECT_INSTANCE: (state, instance) => {
      state.instance = instance
    },
    SELECT_DEMO_PURPOSE: (state, demoPurposeRecord) => {
      state.demoPurposeRecord = demoPurposeRecord
    },
    UPDATE_USER: (state, user) => {
      state.user = user
    },
    UPDATE_FOCUS: (state, focus) => {
      state.focus = focus
    },
    INITIALIZE_FIRESTORE: (state, token) => {
      // Initialize Firebase
      const config = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
      }

      const firebaseApp = firebase.apps.length < 1 ? firebase.initializeApp(config, 'steelDemo') : firebase.app('steelDemo')
      // const firebaseApp = firebase.initializeApp(config);

      // export firestore database
      state.firestoreDB = firebaseApp.firestore()
      state.firestoreAuth = firebaseApp.auth()
      state.firestoreAuth.signInWithCustomToken(token)
        .then((success) => {
          state.authenticated = true
          return success
        })
        .catch(function (error) {
          const errorCode = error.code
          const errorMessage = error.message
          console.log(errorCode + ' ' + errorMessage)
        })
    },
    UPDATE_SNAPSHOT_ID: (state, snapshotId) => {
      state.snapshotId = snapshotId
    },
    UPDATE_SNAPSHOT_NAME: (state, snapshotName) => {
      state.snapshotName = snapshotName
    },
    UPDATE_SNAPSHOTNBR: (state, snapshotNbr) => {
      state.snapshotNbr = snapshotNbr
    },
    UPDATE_CREATE_SNAPSHOT: (state, createSnapshot) => {
      state.createSnapshot = createSnapshot
    },
    UPDATE_DELETE_SNAPSHOT: (state, deleteSnapshot) => {
      state.deleteSnapshot = deleteSnapshot
    },
    UPDATE_START_SNAPSHOT: (state, startSnapshot) => {
      state.startSnapshot = startSnapshot
    },
    UPDATE_SNAPSHOT_CATEGORY: (state, snapshotCategory) => {
      state.snapshotCategory = snapshotCategory
    },
    UPDATE_TERMINATE_INSTANCE: (state, terminateInstance) => {
      state.terminateInstance = terminateInstance
    },
    UPDATE_IP_ADDRESS: (state, ipAddress) => {
      state.ipAddress = ipAddress
    },
    UPDATE_OTHER_MAIL: (state, otherMail) => {
      state.otherMail = otherMail
    },
    UPDATE_MAIL_USERNAME: (state, mailUserName) => {
      state.mailUserName = mailUserName
    },
    UPDATE_LAST_LOGIN: (state, lastLogin) => {
      state.lastLogin = lastLogin
    },
    UPDATE_STEELDEMO: (state, steeldemoUpdates) => {
      state.steeldemoUpdates = steeldemoUpdates
    }
  },
  actions: {

  }
})
