import Vue from 'vue'
import msal from 'vue-msal'
import BootstrapVue from 'bootstrap-vue'
import Vuetify from 'vuetify'
import VueYoutube from 'vue-youtube'
import ToggleButton from 'vue-js-toggle-button'
import JsonExcel from 'vue-json-excel'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store/steelDemoStore'

import VeeValidate from 'vee-validate'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import the plugin here
Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_AUTH_APP_CLIENT_ID,
    authority: process.env.VUE_APP_AUTH_APP_IDENTITY_PROVIDER + process.env.VUE_APP_AUTH_APP_AUDIENCE,
    redirectUri: window.location.origin
  },
  request: {
    scopes: ['openid', 'profile']
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
})

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#B0116B',
        secondary: '#253766',
        error: '#d50000'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
}

Vue.component('downloadExcel', JsonExcel)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})
Vue.use(Vuetify)
Vue.use(BootstrapVue)
Vue.use(VueYoutube)
Vue.use(ToggleButton)

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  created () {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn()
    }
  },
  render: h => h(App)
}).$mount('#app')
